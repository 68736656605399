<template>
  <div
    class="flex items-center justify-center gap-4 text-xs text-gray-400 dark:text-gray-500"
  >
    <NuxtLinkLocale to="/terms" class="hover:underline">
      {{ $t("auth.shared.authFooter.terms") }}
    </NuxtLinkLocale>
    <div class="h-4 border-r border-gray-300 dark:border-gray-600" />
    <NuxtLinkLocale to="/privacy" class="hover:underline">
      {{ $t("auth.shared.authFooter.privacy") }}
    </NuxtLinkLocale>
  </div>
</template>
